/* eslint-disable no-nested-ternary */
import { get, sortBy } from 'lodash';
import { IAGM } from 'Types/Agm';
import generatePassageMap from './generatePassageMap';
import getNextAgm from './getNextAgm';
import mapAgms from './mapAgms';

function generateMapMarkers(agms: IAGM[], passages: any[]) {
    const passageMap = generatePassageMap(passages);

    const mappedAgms = mapAgms(agms);

    let hasPassed = false;
    const markers = [...(agms || [])].reverse().map((agm, idx) => {
        hasPassed = hasPassed || !!passageMap[agm.id];
        const isValve = agm.agm_type === 'Valve';
        const isCurrent = get(getNextAgm(mappedAgms, passageMap), 'id') === agm.id;
        const markerType =
            idx === 0
                ? 'trap'
                : idx === (mappedAgms || []).length - 1
                ? 'launch'
                : agm.agm_type === 'Valve'
                ? 'valve'
                : agm.agm_type === 'Pre-Depoy AGM' || agm.agm_type === 'Pre-Deploy AGM'
                ? 'predeploy'
                : 'normal';

        return {
            position: {
                lat: parseFloat(agm.latitude),
                lng: parseFloat(agm.longitude),
            },
            id: agm.id,
            data: {
                ...agm,
                markerType,
                hasPassed,
                isValve,
                isCurrent,
                passage: passageMap[agm.id],
            },
        };
    });

    return sortBy(markers, (m) => m.data.marker_mile_number);
}

export default generateMapMarkers;
