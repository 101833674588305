/* eslint-disable @typescript-eslint/naming-convention */

import { OverlayView } from '@react-google-maps/api';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
// import usePigLocation from 'Hooks/usePigLocation';
// import { useMemo } from 'react';
// import { BsPiggyBankFill } from 'react-icons/bs';
import { noop } from 'lodash';
import { IAGM } from 'Types/Agm';
import lodashOrderBy from 'lodash/orderBy';
import haversineDistance from 'Helpers/haversineDistance';
import getAgmsWithMPH from 'Helpers/getAgmsWithMPH';
// import { BsPiggyBankFill } from 'react-icons/bs';
import BsPiggyBankFill from 'Assets/pig_transparent4.png';

import { firestore } from '../../firebase';

interface ICoords {
    lat: number;
    lng: number;
}
// Converts from degrees to radians.
function toRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

// Converts from radians to degrees.
function toDegrees(radians: number) {
    return (radians * 180) / Math.PI;
}

function calculateBearing({ lat: lat1, lng: lng1 }: ICoords, { lat: lat2, lng: lng2 }: ICoords): number {
    const startLat = toRadians(lat1);
    const startLng = toRadians(lng1);
    const destLat = toRadians(lat2);
    const destLng = toRadians(lng2);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x =
        Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);

    brng = toDegrees(brng);
    return Math.round(brng);
}

interface IPigLocator {
    pipe_id: string;
    test_id: string;
    agms: IAGM[];
    currentAGM: IAGM;
}

function PigLocator({ pipe_id, test_id, agms: _agms, currentAGM }: IPigLocator) {
    const agms: (IAGM & { eta?: string; etaStamp?: number; mph: number })[] = useMemo(
        () => lodashOrderBy(getAgmsWithMPH(_agms), 'order_reference', 'desc'),
        [_agms],
    );

    const [now, setNow] = useState(new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const [estimatedPigLocation, setEstimatedPigLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [lastKnownPigLocation, setLastKnownPigLocation] = useState<any>(null);
    const [bearing, setBearing] = useState(0);

    const nextAgm = useMemo(() => {
        // TODO replace currentAGM with endemic derivation
        let nextAgmInOrderIndex = agms.findIndex((agm) => agm.id === currentAGM?.id);
        let nextAgmInOrder = agms[nextAgmInOrderIndex];

        while (
            nextAgmInOrder?.agm_type?.toLowerCase?.()?.includes?.('pre') &&
            (nextAgmInOrder?.etaStamp ?? 1e10) < now &&
            nextAgmInOrderIndex >= 0
        ) {
            nextAgmInOrderIndex -= 1;
            nextAgmInOrder = agms[nextAgmInOrderIndex];
        }
        return nextAgmInOrder;

        // return nextAgmIn
    }, [currentAGM, agms, now]);

    const previousAgm = useMemo(() => {
        const thisAgmIndex = agms.findIndex((agm) => agm.id === nextAgm?.id);
        const lastAgmIndex = thisAgmIndex + 1;
        if (lastAgmIndex >= 0) {
            const lastAgm = agms[lastAgmIndex];

            return lastAgm;
        }
        return null;
    }, [agms, nextAgm]);
    // can calculate the last AGM with

    useEffect(() => {
        const pigLocationsRef = collection(firestore, 'pipelines', pipe_id, 'tests', test_id, 'pigLocation');
        const q = query(pigLocationsRef, orderBy('timestamp', 'desc'));
        return onSnapshot(q, ({ docs }) => {
            if (docs.length > 0 && nextAgm && currentAGM) {
                setLastKnownPigLocation(docs[0].data() as any);
            }
        });
    }, [pipe_id, test_id, nextAgm, currentAGM]);

    useEffect(() => {
        if (!lastKnownPigLocation || !nextAgm || !previousAgm) {
            return;
        }
        const {
            lastKnownLocation: { longitude: _longitude, latitude: _latitude },
            lastKnownVelocity,
            timestamp: _timestamp,
        } = lastKnownPigLocation;

        // override lat lng timestamp
        // keep last known location
        const longitude = previousAgm.longitude ?? `${_longitude}`;
        const latitude = previousAgm.latitude ?? `${_latitude}`;
        const timestamp = previousAgm.unix_epoch_stamp ?? previousAgm.etaStamp ?? _timestamp;

        // calculate the distance this should have traveled
        // const distanceBetweenAgms = Math.abs(currentAGM.run_distance - nextAgm.run_distance) / 5280

        const distanceToNextAgm = haversineDistance(
            { lat: parseFloat(latitude), lng: parseFloat(longitude) },
            { lat: parseFloat(nextAgm.latitude), lng: parseFloat(nextAgm.longitude) },
        );

        const distanceTraveled = (lastKnownVelocity * (now - timestamp)) / (1000 * 60 * 60);

        const proportionalDistance = Math.min(distanceTraveled / distanceToNextAgm, 0.999999999);
        // since proportionalDistance is greater than some value...
        setEstimatedPigLocation({
            lat:
                parseFloat(nextAgm.latitude) * proportionalDistance + parseFloat(latitude) * (1 - proportionalDistance),
            lng:
                parseFloat(nextAgm.longitude) * proportionalDistance +
                parseFloat(longitude) * (1 - proportionalDistance),
        });
    }, [lastKnownPigLocation, now, previousAgm, nextAgm]);

    useEffect(() => {
        try {
            if (nextAgm?.latitude && nextAgm.longitude && estimatedPigLocation?.lat && estimatedPigLocation?.lng) {
                // TODO update bearing
                const newBearing = calculateBearing(
                    {
                        lat: parseFloat(`${estimatedPigLocation?.lat}`),
                        lng: parseFloat(`${estimatedPigLocation?.lng}`),
                    },
                    {
                        lat: parseFloat(nextAgm.latitude),
                        lng: parseFloat(nextAgm.longitude),
                    },
                );
                if (newBearing !== 0) {
                    setBearing(newBearing);
                }
            }
        } catch (error) {
            noop();
        }
    }, [nextAgm, estimatedPigLocation, now]);

    if (!estimatedPigLocation) {
        return null;
    }

    return (
        <OverlayView key="pigLocation" position={estimatedPigLocation} mapPaneName="overlayLayer">
            <img
                alt="Maxwell"
                style={{ height: 60, transform: `rotate(${bearing}deg)` }}
                height={70}
                src={BsPiggyBankFill}
            />
        </OverlayView>
    );
    // );
}

export default PigLocator;
