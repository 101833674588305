import React, { ChangeEvent, FormEvent, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { auth } from '../firebase';
import logo from '../Assets/logo.png';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event: FormEvent<HTMLFormElement>, mail: string, pass: string) => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, mail, pass).catch((errors) => {
            setError(errors.message.split('.')[0]);
        });
    };

    const onChangeHandler = (setFunc: (i: string) => void) => (evt: ChangeEvent<HTMLInputElement>) =>
        setFunc(evt.currentTarget.value);

    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <div className="flex flex-col">
                <div className="mt-32 text-center mx-auto">
                    <img src={logo} alt="Logo" className="text-center mx-auto h-40" />
                    <p className="text-lg font-medium text-center text-white mt-4">Maxwell Pipeline Tracking</p>
                </div>
                <div className="text-center mt-16">
                    {error !== null && (
                        <div className="py-4 bg-primaryTheme w-full text-white text-center mb-3">{error}</div>
                    )}
                    <form
                        className="flex-col"
                        onSubmit={(event) => {
                            signInWithEmailAndPasswordHandler(event, email, password);
                        }}
                    >
                        <div className="my-4">
                            <input
                                type="email"
                                name="userEmail"
                                value={email}
                                onChange={onChangeHandler(setEmail)}
                                placeholder="Email"
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="my-4">
                            <input
                                type="password"
                                name="userPassword"
                                value={password}
                                onChange={onChangeHandler(setPassword)}
                                placeholder="Password"
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="mt-8">
                            <button
                                type="submit"
                                className="bg-primaryTheme hover:bg-blue-900 text-white font-bold py-2 px-4 rounded w-72"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default LoginPage;
