import format from 'date-fns-tz/format';
import formatWeldRuns from 'Helpers/formatWeldRuns';
import getAgmsWithMPHS from 'Helpers/getAgmsWithMPH';
import mergePassagesWeldRuns from 'Helpers/mergePassagesWeldRuns';
import useAgms from 'Hooks/useAgms';
import useMixpanel from 'Hooks/useMixpanel';
import usePassages from 'Hooks/usePassages';
import useWeldRuns from 'Hooks/useWeldRuns';
import { useEffect, useMemo } from 'react';

function Summary({ testId, pipeId }: { testId?: string; pipeId?: string }) {
    const [agms] = useAgms({ testId, pipeId });
    const [passages] = usePassages({ testId, pipeId });
    const [weldRuns] = useWeldRuns({ testId, pipeId });

    const formattedWeldRuns = useMemo(() => formatWeldRuns(weldRuns), [weldRuns]);

    const mergedPassagesAndWeldRuns = useMemo(
        () => mergePassagesWeldRuns(getAgmsWithMPHS(agms), passages, formattedWeldRuns),
        [agms, formattedWeldRuns, passages],
    );

    const { track } = useMixpanel();

    useEffect(() => {
        track('Opened Summary', {
            testId,
            pipeId,
        });
    }, [pipeId, testId, track]);

    const tableRows = mergedPassagesAndWeldRuns.map((item: any) => {
        return (
            <tr key={item.start}>
                <td className="p-4 table-cell text-xs md:text-sm text-gray-600">
                    {format(new Date(item.start), 'MMMM dd yyyy @ H:mm a')}
                </td>
                <td className="p-4 md:w-auto text-xs md:text-sm font-medium text-gray-600 sm:pl-6">
                    {item.marker_mile_number ? `AGM #${item.marker_mile_number} passage` : item.contractor}
                </td>
                <td className="p-4 text-xs md:text-sm text-gray-600">
                    {item.is_passage ? `${item.mph}mph` : item.weld_joint_speed}
                </td>
                <td className="p-4 text-xs md:text-sm text-gray-600">{item.is_passage ? `${item.notes}` : ''}</td>
            </tr>
        );
    });

    if (!mergedPassagesAndWeldRuns.length) {
        return <div className="w-full text-center text-lg font-bold mt-8">None recorded yet</div>;
    }

    return (
        <div className="px-0 md:px-6">
            <table className="min-w-full divide-y divide-gray-30 border-2 border-t-gray-100">
                <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="p-4 sticky top-0 z-10 border-b border-gray-300 bg-gray-50 text-left text-xs md:text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                            Start
                        </th>
                        <th
                            scope="col"
                            className="p-4 sticky top-0 z-10 bg-gray-50 text-left text-xs md:text-sm font-semibold text-gray-900"
                        >
                            Contractor
                        </th>
                        <th
                            scope="col"
                            className="p-4 sticky top-0 z-10 bg-gray-50 truncate text-left text-xs md:text-sm font-semibold text-gray-900"
                        >
                            MPH
                        </th>
                        <th
                            scope="col"
                            className="p-4 sticky top-0 z-10 bg-gray-50 text-left text-xs md:text-sm font-semibold text-gray-900"
                        >
                            Notes
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white border-2">{tableRows}</tbody>
            </table>
        </div>
    );
}

export default Summary;
