import mixpanel from 'mixpanel-browser';

mixpanel.init('e5412e4ff19e4e1286f5e27b8dac051d', { debug: true });

const useMixpanel = () => {
    const track = (eventName: string, options?: any) => {
        mixpanel.track(eventName, options);
    };

    return { track };
};

export default useMixpanel;
