import { useEffect, useState, useMemo } from 'react';
import { onSnapshot, orderBy, query, collection } from 'firebase/firestore';
import { IAGM } from 'Types/Agm';
import { firestore } from '../firebase';

interface IUseAgmsProps {
    testId?: string;
    pipeId?: string;
}

function useAgms({ testId, pipeId }: IUseAgmsProps) {
    const [agms, setAgms] = useState<Array<IAGM>>([]);

    const agmsWithState = useMemo(() => {
        const greatestRightIndexPassed = [...agms].reverse().findIndex((agm) => agm.passed === true);
        const greatestIndexPassed = greatestRightIndexPassed < 0 ? -1 : agms.length - 1 - greatestRightIndexPassed;
        const greatestIndexNotPassed = greatestIndexPassed + 1;
        return agms.map((agm, idx) => {
            let status = 'PASSED';
            if (idx > greatestIndexNotPassed) {
                status = 'NOT_PASSED';
            } else if (idx > greatestIndexPassed) {
                status = 'CURRENT';
            }

            return {
                ...agm,
                status,
            };
        });
    }, [agms]);

    useEffect(() => {
        if (!pipeId || !testId) {
            return () => {};
        }

        const q = query(collection(firestore, `/pipelines/${pipeId}/tests/${testId}/agms`), orderBy('order_reference'));
        return onSnapshot(q, (querySnapshot) => {
            setTimeout(() => {
                setAgms(
                    querySnapshot.docs.map((doc) => ({
                        ...(doc.data() as IAGM),
                        id: doc.id,
                    })),
                );
            }, 300);
        });
    }, [pipeId, testId]);

    return [agmsWithState];
}

export default useAgms;
