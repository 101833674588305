import { collection } from 'firebase/firestore';
import useMixpanel from 'Hooks/useMixpanel';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Link, MakeGenerics, useMatch } from 'react-location';
import { firestore } from '../firebase';

export type PipelineGenerics = MakeGenerics<{
    LoaderData: {
        pipelineId: string;
        pipelineToken: string;
        testId: string;
        user: any;
    };
}>;

function Pipeline() {
    const { params } = useMatch<PipelineGenerics>();

    const [value] = useCollection(collection(firestore, `/pipelines/${params.pipelineId}/tests`), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    const pipelineTests: Array<{ description: string; id: string }> =
        value?.docs?.map((doc) => ({ ...(doc.data() as any), id: doc.id })) ?? [];

    const { track } = useMixpanel();

    return (
        <div className="px-4 sm:px-6 lg:px-8 max-w-3xl	mx-auto ">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Tests</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the test runs for this pipeline.</p>
                </div>
                <div className="mt-8 flex flex-col ">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            >
                                                Name
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {pipelineTests.map((test) => (
                                            <tr key={test.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {test.description}
                                                </td>

                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={`${test.id}`}
                                                        onClick={() => {
                                                            track('Switching Test', { testId: test.id });
                                                        }}
                                                    >
                                                        View<span className="sr-only">, {test.description}</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pipeline;
