import { GoogleMap, useJsApiLoader, Polyline, TrafficLayer } from '@react-google-maps/api';
import CustomMarker from 'Components/CustomMarker';
import PigLocator from 'Components/PigLocator';
import useMixpanel from 'Hooks/useMixpanel';
import { useMemo } from 'react';
import { IAGM } from 'Types/Agm';

interface IMap {
    pipe_id: string;
    test_id: string;
    agms: IAGM[];
    currentAGM: IAGM;
    markers: any[];
    currentMarker: any;
}

const containerStyle = {
    flexGrow: 1,
};

function Map({ markers, currentMarker, pipe_id, test_id, agms, currentAGM }: IMap) {
    const center = useMemo(() => {
        return {
            lat: currentMarker?.position?.lat,
            lng: currentMarker?.position?.lng,
        };
    }, [currentMarker?.position?.lat, currentMarker?.position?.lng]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAk_rW5sxM2iA97wCrCDIlef8gsCZb6vPY',
        version: '3.47',
    });

    const { track } = useMixpanel();

    const options = {
        strokeColor: '#38BDF8',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: [],
        zIndex: 1,
    };

    const renderMap = () => {
        return (
            <GoogleMap
                onDrag={() => {
                    track('Map Dragged', { pipe_id, test_id });
                }}
                onZoomChanged={() => {
                    track('Map Zoomed', { pipe_id, test_id });
                }}
                mapContainerStyle={containerStyle}
                options={{ streetViewControl: true, mapTypeId: 'hybrid', mapTypeControl: false }}
                center={center}
                zoom={14}
            >
                <TrafficLayer />
                <PigLocator pipe_id={pipe_id} test_id={test_id} currentAGM={currentAGM} agms={agms} />
                {markers.map((m) => {
                    return m.data ? <CustomMarker key={m.id} marker={m} /> : null;
                })}

                {markers.slice(0, markers.length - 2).map((m, idx) => {
                    return (
                        <Polyline key={m.data.id} options={options} path={[m.position, markers[idx + 1].position]} />
                    );
                })}
            </GoogleMap>
        );
    };

    return isLoaded ? renderMap() : <div className="w-full text-center mt-8">Loading...</div>;
}

export default Map;
