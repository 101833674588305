import { keyBy } from 'lodash';

function generatePassageMap(passages: any[]) {
    return keyBy(
        passages.filter((passage) => !passage.deleted),
        'agm_id',
    );
}

export default generatePassageMap;
