/* eslint-disable react/no-danger */
import downloadRunTable from 'Helpers/downloadRun';
import useAgms from 'Hooks/useAgms';
import usePassages from 'Hooks/usePassages';
import usePipe from 'Hooks/usePipe';
import useTest from 'Hooks/useTest';
import { AiOutlineDownload } from 'react-icons/ai';

function DownloadRun({ pipeId, testId }: { pipeId: string; testId: string }) {
    const [agms] = useAgms({ pipeId, testId });

    const [passages] = usePassages({ pipeId, testId });

    const [pipe] = usePipe({ pipeId });

    const [test] = useTest({ pipeId, testId });

    return (
        <div className="flex space-x-2">
            <button
                onClick={async () => {
                    await downloadRunTable(agms, passages, pipe, test, 'pdf');
                }}
                className="flex items-center space-x-0 bg-green-700 py-2 px-4 rounded text-white"
                type="button"
            >
                <div className="hidden lg:flex mr-2">Download PDF</div>
                <AiOutlineDownload />
            </button>
            <button
                onClick={async () => {
                    await downloadRunTable(agms, passages, pipe, test, 'excel');
                }}
                className="flex items-center space-x-0 bg-green-700 py-2 px-4 rounded rounded-r-none text-white"
                type="button"
            >
                <div className="hidden lg:flex mr-2">Download Sheet</div>
                <AiOutlineDownload />
            </button>
        </div>
    );
}

export default DownloadRun;
