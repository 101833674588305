import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import IWeldRun from 'Types/WeldRun';
import { firestore } from '../firebase';

interface IUseWeldRuns {
    testId?: string;
    pipeId?: string;
}

function useWeldRuns({ testId, pipeId }: IUseWeldRuns) {
    const [weldRuns, setWeldRuns] = useState<Array<IWeldRun>>([]);
    useEffect(() => {
        if (!pipeId || !testId) {
            return () => {};
        }

        const q = query(collection(firestore, `pipelines/${pipeId}/tests/${testId}/runs`), orderBy('end', 'desc'));

        return onSnapshot(q, (querySnapshot) => {
            setWeldRuns(
                querySnapshot.docs.map((doc) => ({
                    ...(doc.data() as IWeldRun),
                    id: doc.id,
                })),
            );
        });
    }, [pipeId, testId]);
    return [weldRuns];
}

export default useWeldRuns;
