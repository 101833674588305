import usePipe from 'Hooks/usePipe';
import useTest from 'Hooks/useTest';
import { AiFillHome } from 'react-icons/ai';
import { FaChevronRight } from 'react-icons/fa';
import { Link, useResolvePath } from 'react-location';

function PipelineNavigation({
    pipeId,
    pipelineToken,
    testId,
}: {
    pipeId: string;
    pipelineToken: string;
    testId?: string;
}) {
    const resolvePath = useResolvePath();

    const [pipe] = usePipe({ pipeId });
    const [test] = useTest({ pipeId, testId });
    return (
        <nav className="px-6 py-4 space-y-6 bg-green-700 text-white md:bg-inherit md:text-black relative">
            <div className="flex items-center space-x-4">
                <h1 className="text-2xl">Maxwell Pipeline</h1>
                <Link
                    to={`${resolvePath('..')}/map`}
                    className="text-sm"
                    getActiveProps={() => ({ className: `font-bold border-b-2 border-white lg:border-green-700` })}
                >
                    Map
                </Link>
                <Link
                    getActiveProps={() => ({ className: `font-bold border-b-2 border-white lg:border-green-700` })}
                    to={`${resolvePath('..')}/summary`}
                    className="text-sm"
                >
                    Summary
                </Link>
            </div>
            <div className="bg-white border-b border-gray-200 py-4 hidden lg:flex" aria-label="Breadcrumb">
                <ol className="w-full mx-auto flex items-center space-x-4 text-sm">
                    <li>
                        <div className="flex items-center">
                            <a href="/home" className="text-gray-400 hover:text-gray-500">
                                <AiFillHome className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <FaChevronRight className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </li>
                    <li>
                        <Link to={`/${pipeId}/${pipelineToken}`} className="flex items-center">
                            Select Test
                        </Link>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <FaChevronRight className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </li>
                    {pipe && (
                        <li>
                            <div className="flex items-center">{pipe?.name}</div>
                        </li>
                    )}
                    {test && (
                        <>
                            <li>
                                <div className="flex items-center">
                                    <FaChevronRight
                                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">{test?.description}</div>
                            </li>
                        </>
                    )}
                </ol>
            </div>
        </nav>
    );
}

export default PipelineNavigation;
