import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../firebase';

interface IUsePipeProps {
    pipeId: string;
}

function usePipe({ pipeId }: IUsePipeProps) {
    const [pipe, setPipe] = useState<any>(null);

    useEffect(() => {
        if (!pipeId) {
            return () => {};
        }

        const pipeRef = doc(firestore, `/pipelines/${pipeId}`);

        return onSnapshot(pipeRef, (querySnapshot) => {
            setPipe(querySnapshot.data());
        });
    }, [pipeId]);

    return [pipe];
}

export default usePipe;
