import { orderBy } from 'lodash';
import moment from 'moment';

function getAgmsWithMPHS(agms: any[]) {
    let last: any = null;
    const start = Math.min(
        ...agms.map(({ unix_epoch_stamp }) => {
            return unix_epoch_stamp || new Date().getTime();
        }),
    );
    return orderBy(agms, (item) => parseInt((item.order_reference || '').replace('-', ''), 10), 'asc').map((agm) => {
        let mph = 5;
        const diameter = parseFloat(agm.diameter) || 0;
        let timeRep = !last ? start : agm.unix_epoch_stamp;
        let diameterFrom0 = diameter || 1;
        if (last && !last.unix_epoch_stamp) {
            diameterFrom0 = diameter + (last.diameterFrom0 || 0);
        }
        if (last) {
            timeRep = timeRep || last.timeRep;
            if (!agm.unix_epoch_stamp) {
                mph = last.mph;
            } else {
                mph = timeRep === last.timeRep ? last.mph : (diameterFrom0 / (timeRep - last.timeRep)) * 1000 * 60 * 60;
            }
            // OTHERWISE
        }
        mph = typeof mph === 'number' ? mph.toFixed(2) : (mph as any);
        if (parseFloat(`${mph}`) === 0) {
            mph = '0.0001' as any;
        }

        const etaStamp =
            last &&
            moment(last.unix_epoch_stamp || last.etaStamp)
                .add(diameter / last.mph, 'hours')
                .unix() * 1000;
        last = {
            ...agm,
            timeRep,
            diameterFrom0,
            mph,
            etaStamp,
            eta: last
                ? moment(last.unix_epoch_stamp || last.etaStamp || last.timeRep)
                      .add(diameter / last.mph, 'hours')
                      .format('hh:mm:ss a')
                : '-',
        };
        return last;
    });
}

export default getAgmsWithMPHS;
