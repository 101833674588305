import { useEffect, useState } from 'react';
import { onSnapshot, orderBy, query, collection } from 'firebase/firestore';
import IPassage from 'Types/Passage';
import { firestore } from '../firebase';

interface IUsePassagesProps {
    testId?: string;
    pipeId?: string;
}

function usePassages({ testId, pipeId }: IUsePassagesProps) {
    const [passages, setPassages] = useState<Array<IPassage>>([]);
    useEffect(() => {
        if (!pipeId || !testId) {
            return () => {};
        }

        const q = query(
            collection(firestore, `/pipelines/${pipeId}/tests/${testId}/passages`),
            orderBy('unix_epoch_stamp'),
        );
        return onSnapshot(q, (querySnapshot) => {
            setPassages(
                querySnapshot.docs.map((doc) => ({
                    ...(doc.data() as IPassage),
                    id: doc.id,
                })),
            );
        });
    }, [pipeId, testId]);
    return [passages];
}

export default usePassages;
