import { keyBy } from 'lodash';
import CreateTestRunExport from 'Services/CreateTestRunExports';

async function downloadRun(agms: any[], passages: any[], pipe: any, test: any, type: 'excel' | 'pdf') {
    const passageMap = keyBy(passages, 'agm_id');
    await CreateTestRunExport(
        `${pipe.name.replace(/ /g, '_')} ${test.description.replace(/ /g, '_')}`,
        agms.map((agm) => ({ ...agm, passage: passageMap[agm.id] })),
        pipe.description.replace('_', ' '),
        test.description.replace('_', ' '),
        pipe.name.replace('_', ' '),
        type
    ).catch((e) => {
        // eslint-disable-next-line no-alert
        alert(e.message);
    });
}

export default downloadRun;
