import { findIndex } from 'lodash';
import { useCallback, useState } from 'react';

export default function useSeekMarkers(currentMarker: any) {
    const [activeMarker, setActiveMarker] = useState(currentMarker);

    const nextMarker = useCallback(
        (marker: any, allMarkers: any[]) => {
            const index = findIndex(allMarkers, (m) => {
                return m?.id === marker?.id;
            });
            if (index + 1 < allMarkers.length) {
                setActiveMarker(allMarkers[index + 1]);
            }
        },
        [setActiveMarker],
    );
    const prevMarker = useCallback(
        (marker: any, allMarkers: any[]) => {
            const index = findIndex(allMarkers, (m) => {
                return m?.id === marker?.id;
            });
            if (index - 1 >= 0) {
                setActiveMarker(allMarkers[index - 1]);
            }
        },
        [setActiveMarker],
    );

    const isMarkerAdjacent = (marker: any, allMarkers: any[], direction: -1 | 1) => {
        const index = allMarkers.indexOf(marker);
        return !!allMarkers[index + direction];
    };

    const jumpToCurrentMarker = useCallback((marker: any, allMarkers: any[]) => {
        setActiveMarker(allMarkers.find((m) => m.data.status === 'CURRENT'));
    }, []);

    return { nextMarker, prevMarker, activeMarker, jumpToCurrentMarker, isMarkerAdjacent };
}
