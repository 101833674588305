import Layout from 'Components/Layout';
import MapWrapper from 'Components/MapWrapper';
import { useMatch } from 'react-location';
import { PipelineGenerics } from './Pipeline';

function PipelineTestMap() {
    const { params } = useMatch<PipelineGenerics>();

    return (
        <Layout
            pipeId={params.pipelineId as string}
            testId={params.testId as string}
            pipeToken={params.pipelineToken as string}
        >
            <MapWrapper pipelineId={params.pipelineId} testId={params.testId} />
        </Layout>
    );
}

export default PipelineTestMap;
