/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { OverlayView } from '@react-google-maps/api';
import { format, fromUnixTime } from 'date-fns';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { IAGMWithMPH } from 'Types/Agm';
// import { BsPiggyBankFill } from 'react-icons/bs';

const bgColors = {
    normal: 'bg-white text-black border-2 border-red-500',
    valve: 'bg-yellow-300 text-black border-2 border-red-500',
    predeploy: 'bg-purple-800',
    launch: 'bg-red-500',
    trap: 'bg-orange-500',
    pig: 'bg-red-800',
};

const borderColors = {
    PASSED: 'border-2 border-green-700',
    NOT_PASSED: 'border-2 border-red-800',
    CURRENT: 'border-2 border-blue-500',
};

const animations = {
    PASSED: 'animate-none',
    NOT_PASSED: 'animate-none',
    CURRENT: 'animate-bounce',
};

interface IMarkerProps {
    position: {
        lat: number;
        lng: number;
    };
    id: string;
    data: IAGMWithMPH & {
        markerType: 'normal' | 'valve' | 'predeploy' | 'launch' | 'trap' | 'pig';
        hasPassed: boolean;
        isValve: boolean;
        isCurrent: boolean;
        passage: any;
        unix_epoch_stamp?: number;
    };
}

function CustomMarker({ marker }: { marker: IMarkerProps }) {
    const [showMarkerWindow, setShowMarkerWindow] = useState(false);

    const toggleMarkerWindow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        setShowMarkerWindow(!showMarkerWindow);
    };

    const borderColor = borderColors[marker.data.status];

    const bgColor = bgColors[marker.data.markerType];

    const animation = animations[marker.data.status];

    if (marker.data.markerType === 'pig') {
        // TO STYLE: IS STOPPED MARKER
        return null;
    }

    return (
        <OverlayView key={marker.id} position={marker.position} mapPaneName="floatPane">
            <div
                className={`relative w-8 h-8 flex items-center justify-center text-md rounded-full ${borderColor}  ${bgColor} ${animation}`}
                onClick={(e) => toggleMarkerWindow(e)}
            >
                {showMarkerWindow && (
                    <div className="absolute flex text-md flex-col -inset-y-40 bg-white rounded w-48 h-40">
                        <div className="bg-green-700 h-12 rounded-t p-4 truncate break-words text-white font-bold flex items-center justify-center">
                            <span className="truncate"> {marker.data.description_of_location}</span>
                            <AiOutlineClose
                                onClick={() => setShowMarkerWindow(false)}
                                className="absolute top-1 right-1 cursor-pointer"
                            />
                        </div>
                        <div className="p-2 pb-4 space-y-3 flex flex-col">
                            <div className="flex items-center">
                                AGM#:
                                <span className="ml-1">{marker.data.marker_mile_number}</span>
                            </div>
                            <div className="flex items-center">
                                MP:
                                <span className="ml-1">{marker.data.mp}</span>
                            </div>
                            <div className="flex items-center">
                                MPH:
                                <span className="ml-1">{marker.data.mph}</span>
                            </div>
                            <div className="flex items-center">
                                ETA:
                                <span className="ml-1">{marker.data.eta}</span>
                            </div>
                            {/* // passage */}
                            {marker.data.unix_epoch_stamp && (
                                <div className="flex items-center">
                                    Passed:
                                    <span className="ml-1">
                                        {format(
                                            fromUnixTime(marker.data.unix_epoch_stamp / 1000),
                                            'MMMM dd yyyy hh:mm:ss a',
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <span className="font-bold">{marker.data.marker_mile_number}</span>
            </div>
        </OverlayView>
    );
}

export default CustomMarker;
