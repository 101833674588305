import PipelineNavigation from 'Components/PipelineNavigation';
import SpeedIndicator from 'Components/SpeedIndicator';
import React from 'react';

function Layout({
    children,
    pipeId,
    pipeToken,
    testId,
}: {
    children: React.ReactNode[] | React.ReactNode;
    pipeId: string;
    pipeToken: string;
    testId: string;
}) {
    return (
        <div className="flex flex-col h-screen">
            <SpeedIndicator testId={testId} pipeId={pipeId} />
            <PipelineNavigation testId={testId} pipelineToken={pipeToken} pipeId={pipeId} />
            {children}
        </div>
    );
}

export default Layout;
