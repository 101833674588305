import { signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { functions, auth } from '../firebase';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export default function HomePage() {
    /// need to subscribe to pipelines
    const [pipelines, setPipelines] = useState<Array<any>>([]);

    useEffect(() => {
        httpsCallable(functions, 'fetchClientPipelines')()
            .then(({ data: { data } }: any) => {
                setPipelines(data.filter(({ hidden }: any) => !hidden));
            })
            .catch((e) => {
                if (`${e.code}`.includes('permission-denied')) {
                    signOut(auth);
                }
            });
    }, []);

    return (
        <div className="flex flex-row h-screen w-full justify-center items-center bg-blue-500">
            <nav className="space-y-1 w-96 bg-white rounded p-4" aria-label="Sidebar">
                <p
                    className={classNames(
                        'bg-gray-100 text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md',
                    )}
                    aria-current="page"
                >
                    <span className="truncate">Pipes</span>
                </p>
                {pipelines.map((pipe) => (
                    <p className="p-2">
                        <a
                            key={pipe.id}
                            href={`/${pipe.id}/${pipe.pipe_token}`}
                            className={classNames(
                                'text-gray-600 hover:bg-gray-50 hover:text-gray-900flex items-center px-3 py-2 text-sm font-medium rounded-md',
                            )}
                        >
                            <span className="truncate">{pipe.name}</span>
                        </a>
                    </p>
                ))}
            </nav>
        </div>
    );
}
