import Excel from 'exceljs';
import { fromUnixTime, subMinutes } from 'date-fns';
import orderBy from 'lodash/orderBy';
import { formatInTimeZone } from 'date-fns-tz';
import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const MILLIS_IN_HOUR = 60 * 1000 * 60;

// eslint-disable-next-line new-cap
const doc = new jsPDF();

const arrayAverage = (array: number[]) => {
    const totalSum = array.reduce((sum, item) => item + sum, 0);
    return totalSum / array.length;
};
/**
 * Takes a blank excel URL, a download name, and AGMs from the run and creates an excel export
 *
 * This export conforms to a very specific format, that this app is kind of built around. Cells and rows
 * are hardcoded to meet this format. This export is used by the pipeline client to collate pig information
 * (like for example, 'there is a hole in the pipe at x run distance')
 * with test run information
 *
 */
const CreateTestRunExport = async (
    name: string,
    agms: any[],
    description: string,
    test: any,
    formattedTitle: any,
    type: 'excel' | 'pdf',
) => {
    // get file contents
    const sheetData = await fetch(
        'https://firebasestorage.googleapis.com/v0/b/burgerbuilder-68377.appspot.com/o/BasisSheet.xlsx?alt=media&token=16e9e5c2-25ba-4811-b294-a08a0b06365b',
    );
    const buffer = await sheetData.arrayBuffer();

    const workbook = new Excel.Workbook();
    const worksheet = await workbook.xlsx.load(buffer);

    //   For all agms....
    worksheet.worksheets[0].getRow(13).getCell(7).value = subMinutes(new Date(), 6 * 60);

    // remove all except the tracking sheet
    [...worksheet.worksheets].forEach(({ name: worksheetName }, idx) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        idx !== 0 && worksheet.removeWorksheet(worksheetName);
    });

    const agmsWithPassages = agms.filter(({ passage }) => !!passage);
    if (agmsWithPassages.length === 0) {
        throw new Error('No passages were recorded for this run yet, so there is no export');
    }

    const leastRecentAgm = agmsWithPassages.sort(
        // eslint-disable-next-line no-unsafe-optional-chaining
        (a, b) => (a.passage?.unix_epoch_stamp || -1) - b.passage?.unix_epoch_stamp,
    )[0];

    const mostRecentAgm = agmsWithPassages
        // eslint-disable-next-line no-unsafe-optional-chaining
        .sort((a, b) => (a.passage?.unix_epoch_stamp || -1) - b.passage?.unix_epoch_stamp)
        .reverse()[0];

    const totalRunHours =
        ((mostRecentAgm?.passage?.unix_epoch_stamp || 0) - (leastRecentAgm?.passage?.unix_epoch_stamp || 0)) /
        MILLIS_IN_HOUR;

    // MPH calculation
    const sortedAgms = orderBy(agms, (item) => parseInt((item.order_reference || '').replace('-', ''), 10), 'asc');

    // This variable is mutated within a foreach!
    // it holds state from previous agms necessary
    // for a mph calculation
    const lastRecordedPassageAgm = {
        mph: 5,
        aggregatedDistance: 0,
        timestamp: leastRecentAgm.passage.unix_epoch_stamp,
    };

    const agmsWithMphs = sortedAgms.map((agm, idx) => {
        // the first mph we SET to 5
        if (idx === 0) {
            return {
                ...agm,
                mph: 5,
            };
        }
        // Aggregate the aggregatedDistance from last recorded passage
        const aggregatedDistance = (parseFloat(agm.diameter) || 0) + lastRecordedPassageAgm.aggregatedDistance;

        if (!agm?.passage?.unix_epoch_stamp) {
            lastRecordedPassageAgm.aggregatedDistance = aggregatedDistance;
            return {
                ...agm,
                mph: lastRecordedPassageAgm.mph,
            };
        }

        // calc mph
        const timeSinceLastRecordedPassage = agm.passage.unix_epoch_stamp - lastRecordedPassageAgm.timestamp;
        const distanceSinceLastRecordedPassage = aggregatedDistance;
        lastRecordedPassageAgm.aggregatedDistance = 0;
        const mph = (distanceSinceLastRecordedPassage / timeSinceLastRecordedPassage) * MILLIS_IN_HOUR;
        lastRecordedPassageAgm.mph = mph;
        lastRecordedPassageAgm.timestamp = agm.passage.unix_epoch_stamp;
        return {
            ...agm,
            mph,
        };
    });

    const autoTableBody: any[] = [];

    agmsWithMphs.forEach((agm, idx) => {
        const row = worksheet.worksheets[0].getRow(idx + 5);
        row.getCell(1).value = agm.description_of_location;
        row.getCell(2).value = agm.run_distance;
        row.getCell(3).value = agm.marker_mile_number;
        row.getCell(4).value = agm.mp;
        row.getCell(5).value = agm.diameter ?? '-';

        row.getCell(7).value = agm?.box1 ?? agm.passage?.box1 ?? ' ';
        row.getCell(8).value = agm?.box2 ?? agm.passage?.box2 ?? ' ';
        row.getCell(9).value = agm.latitude ?? ' ';
        row.getCell(10).value = agm.longitude ?? ' ';

        const autoTableRow = [
            agm.description_of_location,
            agm.run_distance,
            agm.marker_mile_number,
            agm.mp,
            agm.diameter ?? '-',
            '',
            agm?.box1 ?? agm.passage?.box1 ?? ' ',
            agm?.box2 ?? agm.passage?.box2 ?? ' ',
            agm.latitude ?? '',
            agm.longitude ?? '',
        ];

        autoTableBody.push(autoTableRow);
    });

    // find last index for which an agm passage
    const lastPassage = [...agmsWithMphs].reverse().findIndex(({ passage }) => passage?.unix_epoch_stamp);

    const missingPassages: any[] = [];

    const mphList: any[] = [];

    agmsWithMphs.forEach((agm, idx) => {
        const row = worksheet.worksheets[0].getRow(idx + 5);

        const passageTimestamp = agm.passage?.unix_epoch_stamp;
        if (passageTimestamp) {
            mphList.push(agm.mph);
            const when = fromUnixTime(passageTimestamp / 1000);
            row.getCell(6).value = formatInTimeZone(when, 'America/Chicago', 'yyyy-MM-dd HH:mm:ss zzz');

            autoTableBody[idx][5] = formatInTimeZone(when, 'America/Chicago', 'yyyy-MM-dd HH:mm:ss zzz');
        } else if (agmsWithMphs.length - lastPassage > idx - 1) {
            missingPassages.push(idx);
        }
    });

    missingPassages.forEach((idx) => {
        // have a missing passage
        // need to find the previous one that had a passage
        // then find the next one that has a value

        const previousPassage = [...agmsWithMphs]
            .slice(0, idx)
            .reverse()
            .find(({ passage }) => !!passage?.unix_epoch_stamp);
        const nextPassage = [...agmsWithMphs].slice(idx).find(({ passage }) => !!passage?.unix_epoch_stamp);

        if (previousPassage && nextPassage) {
            const row = worksheet.worksheets[0].getRow(idx + 5);

            // abs just in case there's an error, i.e. a tracker presses
            // pass before the previous one goes?
            const timeElapsedInSeconds = Math.abs(
                nextPassage.passage.unix_epoch_stamp - previousPassage.passage.unix_epoch_stamp,
            );
            const distanceDifferenceInFeet = nextPassage.run_distance - previousPassage.run_distance;

            const speedInFeetPerMS = distanceDifferenceInFeet / timeElapsedInSeconds;

            const distanceFromLastTilMissing = Math.abs(agmsWithMphs[idx].run_distance - previousPassage.run_distance);

            const imputedPassageTime =
                distanceFromLastTilMissing / speedInFeetPerMS + previousPassage.passage.unix_epoch_stamp;

            row.getCell(6).value = formatInTimeZone(
                fromUnixTime(imputedPassageTime / 1000),
                'America/Chicago',
                'yyyy-MM-dd HH:mm:ss zzz',
            );
        }
    });

    const totalRunHoursRemainder = totalRunHours % 1;
    const extraMinutes = totalRunHoursRemainder * 60;
    const totalRunMinutesRemainder = extraMinutes % 1;
    const extraSeconds = totalRunMinutesRemainder * 60;

    const formattedTime = `${Math.floor(totalRunHours)}:${`${Math.floor(extraMinutes)}`.padStart(
        2,
        '0',
    )}:${`${Math.round(extraSeconds)}`.padStart(2, '0')}`;

    const autoTableHead = [
        ['', '', '', '', '', '', '', '', '', '', 'Runtime', 'AVG SPD'],
        [
            'Name',
            'Distance',
            'AGM',
            'MP',
            'D',
            'Passage',
            'Box 1',
            'Box 2',
            'Lat',
            'Lng',
            formattedTime,
            `${arrayAverage(mphList.slice(1)).toFixed(2)} mph`,
        ],
    ];

    worksheet.worksheets[0].getRow(1).getCell(1).value = formattedTitle;
    worksheet.worksheets[0].getRow(2).getCell(1).value = description;
    worksheet.worksheets[0].getRow(3).getCell(1).value = test;

    worksheet.worksheets[0].getRow(3).getCell(11).value = 'Total Runtime';
    worksheet.worksheets[0].getRow(4).getCell(11).value = formattedTime;

    worksheet.worksheets[0].getRow(3).getCell(12).value = 'Average Speed';
    worksheet.worksheets[0].getRow(4).getCell(12).value = `${arrayAverage(mphList.slice(1)).toFixed(2)} mph`;

    const d = await workbook.xlsx.writeBuffer();

    const blob = new Blob([d], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    autoTable(doc, {
        head: autoTableHead,
        body: autoTableBody,
        theme: 'grid',
        styles: {
            fontSize: 6,
        },
    });

    return type === 'pdf' ? doc.save(`${name}.pdf`) : FileSaver.saveAs(blob, `${name}.xlsx`);
};

export default CreateTestRunExport;
