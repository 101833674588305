interface CompletedRunSummaryProps {
    avgMPH: string;
    timeTrapped: string;
    totalTime: string;
}

function CompletedRunSummary({ avgMPH, timeTrapped, totalTime }: CompletedRunSummaryProps) {
    return (
        <div className="bg-white py-24 sm:py-32 w-full">
            <div className="mx-auto px-6 lg:px-8">
                <dl className="grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3">
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">Total Time</dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            {totalTime}
                        </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">Trapped</dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            {timeTrapped}
                        </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                        <dt className="text-base leading-7 text-gray-600">Avg. Speed</dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            {avgMPH} MPH
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}

export default CompletedRunSummary;
