import { orderBy, keyBy } from 'lodash';
import commaNumber from 'comma-number';
import { IAGMWithMPH } from 'Types/Agm';
import IPassage from 'Types/Passage';
import { IFormattedWeldRun } from 'Types/WeldRun';

function mergePassagesWeldRuns(agms: IAGMWithMPH[], passages: IPassage[], weldRunsFormatted: IFormattedWeldRun[]) {
    if (agms.length === 0) {
        return [];
    }
    const agmMap = keyBy(agms, 'id');

    const merged = [
        ...passages.map(({ unix_epoch_stamp, agm_id, notes }) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { run_distance, mph, site_description, marker_mile_number } = agmMap[agm_id];
            return {
                id: agm_id,
                is_passage: true,
                start: unix_epoch_stamp,
                run_distance: commaNumber(run_distance),
                mph,
                marker_mile_number,
                site_description,
                notes,
            };
        }),
        ...weldRunsFormatted,
    ];
    return orderBy(merged, (i) => i.start, 'desc');
}

export default mergePassagesWeldRuns;
