import orderBy from 'lodash/orderBy';
import Map from 'Components/Map';
import PipelineLegend from 'Components/PipelineLegend';
import generateMapMarkers from 'Helpers/generateMapMarkers';
import getAgmsWithMPHS from 'Helpers/getAgmsWithMPH';
import useAgms from 'Hooks/useAgms';
import usePassages from 'Hooks/usePassages';
import useSeekMarkers from 'Hooks/useSeekMarkers';
import useTest from 'Hooks/useTest';
import { useMemo, useState } from 'react';
import haversine from 'haversine-distance';
import { IAGM } from 'Types/Agm';
import CompletedRunSummary from 'Components/CompletedRunSummary';
import { differenceInSeconds, format } from 'date-fns';
import PipelineToggleLegend from 'Components/PipelineToggleLegend';

const arrayAverage = (array: number[]) => {
    const totalSum = array.reduce((sum, item) => item + sum, 0);
    return totalSum / array.length;
};

const formatTime = (time: number) => {
    const totalRunHoursRemainder = time % 1;
    const extraMinutes = totalRunHoursRemainder * 60;
    const totalRunMinutesRemainder = extraMinutes % 1;
    const extraSeconds = totalRunMinutesRemainder * 60;

    return `${Math.floor(time)}:${`${Math.floor(extraMinutes)}`.padStart(2, '0')}:${`${Math.round(
        extraSeconds,
    )}`.padStart(2, '0')}`;
};

function MapWrapper({ pipelineId, testId }: { pipelineId?: string; testId?: string }) {
    const [showLegend, setShowLegend] = useState(false);

    const [agms] = useAgms({ pipeId: pipelineId, testId });

    const [passages] = usePassages({ testId, pipeId: pipelineId });

    const markers = useMemo(() => generateMapMarkers(getAgmsWithMPHS(agms), passages), [agms, passages]);

    const [test] = useTest({ testId, pipeId: pipelineId });

    const agmsWithMilesPerHour: Array<
        IAGM & { mph: string; timeRep?: number; passage?: { unix_epoch_stamp: number } }
    > = useMemo(() => getAgmsWithMPHS(agms), [agms]);

    const currentAGM: (IAGM & { mph: string; aggregateStoppedTime?: number }) | null = useMemo(
        () => agmsWithMilesPerHour.find((agm) => agm.status === 'CURRENT') ?? null,
        [agmsWithMilesPerHour],
    );

    /// CALCULATE PIG STOPPED POSITION

    const markersWithPig = useMemo(() => {
        if (!test?.pig_stopped || markers.length === 0 || !test?.pig_stopped_stamp) {
            return markers;
        }
        const currentMarker = markers.find((marker) => marker.data.status === 'CURRENT');
        const previousMarker = orderBy(markers, 'data.order_reference', 'desc').find(
            (marker) => marker.data.status === 'PASSED',
        );
        if (currentAGM === null) {
            return markers;
        }
        if (!currentMarker?.position || !previousMarker?.position || !previousMarker.data.unix_epoch_stamp) {
            return markers;
        }

        const { mph, aggregateStoppedTime = 0 } = currentAGM;

        const timeWaiting = new Date().getTime() - (test?.pig_stopped_stamp || 0) + aggregateStoppedTime ?? 0;
        const timeSincePassageMinusWaiting = new Date().getTime() - previousMarker.data.unix_epoch_stamp - timeWaiting;

        const distanceBetweenLastAgmAndThis = haversine(currentMarker.position, previousMarker.position) * 0.000621371;
        const expectedMileageTraveled = parseFloat(mph) * (timeSincePassageMinusWaiting / 1000 / 60 / 60);

        const percentTraveled = Math.min(expectedMileageTraveled / distanceBetweenLastAgmAndThis, 1);

        return [
            ...markers,
            ...(test?.pig_stopped && markers.length > 0
                ? [
                      {
                          position: {
                              lat:
                                  currentMarker.position.lat * percentTraveled +
                                  previousMarker.position.lat * (1 - percentTraveled),
                              lng:
                                  currentMarker.position.lng * percentTraveled +
                                  previousMarker.position.lng * (1 - percentTraveled),
                          },
                          data: {
                              markerType: 'pig',
                          },
                          id: 'STOPPED',
                      },
                  ]
                : []),
        ];
    }, [test?.pig_stopped, test?.pig_stopped_stamp, markers, currentAGM]);

    const currentMarker = useMemo(() => {
        return markers.find((m: any) => m.data.status === 'CURRENT');
    }, [markers]);

    const runFinished = useMemo(
        () => markers.length > 0 && markers.reduce((result, marker) => result && marker.data.status === 'PASSED', true),
        [markers],
    );

    const avgMPH = useMemo(
        () =>
            arrayAverage(
                agmsWithMilesPerHour
                    .map((agm) => {
                        const mph = parseFloat(agm.mph);
                        if (!Number.isNaN(mph) && agm.unix_epoch_stamp) {
                            return mph;
                        }
                        return null;
                    })
                    .filter(Boolean)
                    .slice(1) as number[],
            ),
        [agmsWithMilesPerHour],
    );

    const timeTrapped = useMemo(() => {
        if (passages.length) {
            const [trapped] = passages.reverse();

            return format(trapped.unix_epoch_stamp, 'MMMM dd hh:mm a');
        }

        return 'N/A';
    }, [passages]);

    const totalTimeRun = useMemo(() => {
        const firstAGM = agmsWithMilesPerHour[agmsWithMilesPerHour.length - 1];

        const [lastAGM] = agmsWithMilesPerHour;
        const lastAgmTime = lastAGM?.unix_epoch_stamp ?? lastAGM?.timeRep;
        if (firstAGM && firstAGM.unix_epoch_stamp && lastAgmTime) {
            const diff = differenceInSeconds(firstAGM.unix_epoch_stamp, lastAgmTime);
            const hours = diff / 3600;
            return hours;
        }
        return 0;
    }, [agmsWithMilesPerHour]);

    const { prevMarker, nextMarker, activeMarker, jumpToCurrentMarker, isMarkerAdjacent } =
        useSeekMarkers(currentMarker);

    return (
        <>
            <PipelineToggleLegend
                pipeId={pipelineId as string}
                testId={testId as string}
                showLegend={showLegend}
                setShowLegend={setShowLegend}
            />
            <div className="flex flex-col lg:flex-row h-full">
                {showLegend && (
                    <PipelineLegend
                        jumpToCurrentMarker={jumpToCurrentMarker}
                        currentMarker={activeMarker || currentMarker}
                        isMarkerAdjacent={isMarkerAdjacent}
                        markers={markers}
                        next={nextMarker}
                        prev={prevMarker}
                    />
                )}
                <div className={`flex-row flex-grow ${runFinished ? 'hidden' : 'flex'}`}>
                    <Map
                        pipe_id={pipelineId as string}
                        test_id={testId as string}
                        agms={agms as IAGM[]}
                        currentAGM={currentAGM as IAGM}
                        markers={[...markersWithPig]}
                        currentMarker={activeMarker || currentMarker}
                    />
                </div>
                {runFinished && (
                    <CompletedRunSummary
                        avgMPH={`${Math.round(avgMPH * 100) / 100}`}
                        totalTime={formatTime(totalTimeRun)}
                        timeTrapped={timeTrapped}
                    />
                )}
            </div>
        </>
    );
}

export default MapWrapper;
