import { differenceInMilliseconds, fromUnixTime } from 'date-fns/esm';
import getAgmsWithMPHS from 'Helpers/getAgmsWithMPH';
import useAgms from 'Hooks/useAgms';
import useWeldRuns from 'Hooks/useWeldRuns';
import { useEffect, useMemo, useState } from 'react';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import useTest from 'Hooks/useTest';
import orderBy from 'lodash/orderBy';
import logo from 'Assets/logo-white.png';
import { noop } from 'lodash';

function SpeedIndicator({ pipeId, testId }: { pipeId: string; testId: string }) {
    const [agms] = useAgms({ testId, pipeId });

    const [test] = useTest({ pipeId, testId });

    const [now, setNow] = useState(new Date());

    const [weldRuns] = useWeldRuns({ testId, pipeId });

    const agmsWithMilesPerHour = useMemo(() => getAgmsWithMPHS(agms), [agms]);

    const currentAGM = useMemo(
        () => agmsWithMilesPerHour.find((agm) => agm.status === 'CURRENT'),
        [agmsWithMilesPerHour],
    );

    const lastAGM = useMemo(() => orderBy(agmsWithMilesPerHour, 'order_reference', 'desc')[0], [agmsWithMilesPerHour]);

    // console.log(agmsWithMilesPerHour);

    const lastWeldRun = useMemo(() => weldRuns[0] ?? null, [weldRuns]);

    const previousAGM = useMemo(
        () => agmsWithMilesPerHour.reverse().find((agm) => agm.status === 'PASSED'),
        [agmsWithMilesPerHour],
    );

    /// distance = lastWeldRun distance
    const totalDistanceRun = useMemo(() => {
        // find
        let passedAgmsDistance = 0.1;

        try {
            const passedAgmsDistanceFeet = orderBy(agmsWithMilesPerHour, 'order_referenced', 'desc').find(
                ({ passed }) => passed,
            )?.run_distance;

            if (passedAgmsDistanceFeet) {
                passedAgmsDistance = passedAgmsDistanceFeet / 5280;
            }
        } catch (error) {
            noop();
        }

        const lastPassageTime = previousAGM?.unix_epoch_stamp;
        const nextPassageEta = currentAGM?.etaStamp;

        if (typeof lastPassageTime === 'number' && typeof nextPassageEta === 'number' && currentAGM.diameterFrom0) {
            const timeBetweenAgms = nextPassageEta - lastPassageTime;
            const proportionTraveled = Math.min(Math.max((now.getTime() - lastPassageTime) / timeBetweenAgms, 0), 1);

            return passedAgmsDistance + (proportionTraveled ?? 0) * currentAGM.diameterFrom0;
        }
        return passedAgmsDistance;
    }, [currentAGM, now, agmsWithMilesPerHour, previousAGM]);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 10 * 1000);

        return () => clearInterval(interval);
    }, []);

    const mph: string = useMemo(() => {
        if (lastWeldRun) {
            const { elapsed, distance } = lastWeldRun;

            return (distance / 5280 / (parseFloat(String(elapsed)) / (60 * 60))).toFixed(2);
        }
        return '-';
    }, [lastWeldRun]);

    const timeSinceLastWeldRun = useMemo(() => {
        if (!lastWeldRun) {
            return null;
        }

        const elapsedSeconds = differenceInMilliseconds(now, fromUnixTime(lastWeldRun.end)) / 1000;
        if (elapsedSeconds < 60) {
            return `< 1 minute ago`;
        }
        if (elapsedSeconds < 60 * 60) {
            return `${(elapsedSeconds / 60).toFixed(2)} minutes ago`;
        }
        return `${(elapsedSeconds / 60 / 60).toFixed(2)} hours ago`;
    }, [lastWeldRun, now]);

    return (
        <div
            className={`${
                test?.pig_stopped ? 'bg-red-500 animate-pulse' : 'bg-green-700'
            } lg:flex items-center w-full lg:p-6 text-white space-x-4 relative min-h-[120px] pt-[10px]`}
        >
            <BsFillExclamationOctagonFill className="hidden lg:block" size="20" />
            <div className="font-bold text-sm">ETA To Trap: {lastAGM?.eta}</div>
            <div className="font-bold text-sm">ETA To Next: {currentAGM?.eta}</div>
            <div className="font-bold text-sm">Current MPH: {currentAGM && <small>({currentAGM.mph})</small>}</div>
            <div className="font-bold text-sm">
                Weld Count MPH: {mph} {timeSinceLastWeldRun && <small>({timeSinceLastWeldRun})</small>}
            </div>
            {totalDistanceRun && (
                <div className="font-bold text-sm">
                    Estimated Distance Covered: <small>({totalDistanceRun.toFixed(2)})</small>
                </div>
            )}

            <div className="absolute right-0 top-[10px]">
                <img src={logo} alt="Logo" height={40} className="h-[100px]" />
            </div>
        </div>
    );
}

export default SpeedIndicator;
