interface ICoordinates {
    lat: number;
    lng: number;
}

const haversineDistance = (coords1: ICoordinates, coords2: ICoordinates) => {
    const toRad = (x: number) => (Math.PI / 180) * x;
    const R = 6371; // km
    const x1 = coords2.lat - coords1.lat;
    const dLat = toRad(x1);
    const x2 = coords2.lng - coords1.lng;
    const dLon = toRad(x2);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(coords1.lat)) * Math.cos(toRad(coords2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d / 1.60934;
};

export default haversineDistance;
