import { findLastIndex } from 'lodash';

function getNextAgm(mappedAgms: any[], passageMap: any) {
    const index = findLastIndex(mappedAgms, (agm) => {
        return passageMap[agm.id] && passageMap[agm.id].deleted;
    });
    return mappedAgms[index + 1];
}

export default getNextAgm;
