interface Props {
    next: (marker: any, allMarkers: any[]) => void;
    prev: (marker: any, allMarkers: any[]) => void;
    isMarkerAdjacent: (marker: any, allMarkers: any[], direction: 1 | -1) => boolean;
    jumpToCurrentMarker: (marker: any, allMarkers: any[]) => void;
    currentMarker: any;
    markers: any[];
}

function PipelineLegend({ next, prev, markers, currentMarker, jumpToCurrentMarker, isMarkerAdjacent }: Props) {
    return (
        <div className="flex flex-col p-4 w-full lg:w-1/5 bg-gray-100">
            <div className="flex">
                <div className="bg-green-700 max-w-lg px-2 py-1 text-white text-xs lg:text-md font-bold rounded-md truncate">
                    VIEWING AGM: {currentMarker?.data.marker_mile_number} {currentMarker.data.site_description}
                </div>
            </div>

            <div className="space-y-2 text-xs lg:text-sm flex flex-wrap lg:block">
                <p className="font-bold text-lg text-center mt-4 hidden md:inherit">Legend</p>
                <div className="space-y-2 space-x-2 flex lg:space-x-0 lg:block">
                    <p className="hidden lg:block font-bold text-sm">AGM status</p>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full border-2 border-green-500" />
                        <p className="ml-1">Passed</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full border-2 border-red-500" />
                        <p className="ml-1">Not passed</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full border-2 border-blue-500" />
                        <p className="ml-1">Current</p>
                    </div>
                </div>
                <div className="space-y-2 space-x-2 flex lg:space-x-0 lg:block">
                    <p className="hidden lg:block font-bold text-sm">AGM type</p>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full bg-gray-500" />
                        <p className="ml-1">AGM</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full bg-yellow-500" />
                        <p className="ml-1">Valve</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full bg-purple-500" />
                        <p className="ml-1">Pre-Deploy</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 rounded-full bg-red-500" />
                        <p className="ml-1">Launch</p>
                    </div>
                </div>
            </div>

            <div className="mt-6 lg:mt-auto text-xs flex justify-between">
                <button
                    onClick={() => prev(currentMarker, markers)}
                    className={`bg-green-700 flex-1 mx-0.5 text-white py-2 px-3 lg:py-1 lg:px-2  font-bold rounded-sm opacity-${
                        !isMarkerAdjacent(currentMarker, markers, -1) ? '100' : '30'
                    }`}
                    disabled={!isMarkerAdjacent(currentMarker, markers, -1)}
                    type="button"
                >
                    PREV
                </button>
                <button
                    onClick={() => jumpToCurrentMarker(currentMarker, markers)}
                    className={`bg-green-700 flex-1 mx-0.5 text-white py-2 px-3 lg:py-1 lg:px-2  font-bold rounded-sm opacity-${
                        !currentMarker?.data.isCurrent ? '30' : '100'
                    }`}
                    disabled={currentMarker?.data.isCurrent}
                    type="button"
                >
                    CURRENT
                </button>
                <button
                    onClick={() => next(currentMarker, markers)}
                    className={`bg-green-700 flex-1 mx-0.5 text-white py-2 px-3 lg:py-1 lg:px-2  font-bold rounded-sm opacity-${
                        !isMarkerAdjacent(currentMarker, markers, 1) ? '30' : '100'
                    }`}
                    disabled={!isMarkerAdjacent(currentMarker, markers, 1)}
                    type="button"
                >
                    NEXT
                </button>
            </div>
        </div>
    );
}

export default PipelineLegend;
