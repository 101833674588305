import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../firebase';

interface IUseTestProps {
    testId?: string;
    pipeId?: string;
}

function useTest({ testId, pipeId }: IUseTestProps) {
    const [test, setTest] = useState<any>(null);

    useEffect(() => {
        if (!testId || !pipeId) {
            return () => {};
        }

        const pipeRef = doc(firestore, `/pipelines/${pipeId}/tests/${testId}`);

        return onSnapshot(pipeRef, (querySnapshot) => {
            setTest(querySnapshot.data());
        });
    }, [pipeId, testId]);

    return [test];
}

export default useTest;
