import { signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
// import PipelineTest from 'Pages/PipelineTest';
import { httpsCallable } from 'firebase/functions';
import HomePage from 'Pages/Home';
import LoginPage from 'Pages/Login';
import PipelinePage from 'Pages/Pipeline';
import PipelineTestMap from 'Pages/PipelineTestMap';
import PipelineTestSummary from 'Pages/PipelineTestSummary';
import { useEffect, useState } from 'react';
import {
    DefaultGenerics,
    LoaderFn,
    MakeGenerics,
    Navigate,
    ReactLocation,
    Route,
    Router,
    useMatch,
} from 'react-location';
import { auth, functions } from './firebase';

const location = new ReactLocation();

const v2AuthDataViewer = httpsCallable(functions, 'v2AuthDataViewer');

const checkAuthUser = () => {
    return new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => resolve(user));
    });
};

const PipelineFetcher: LoaderFn<DefaultGenerics> = async ({ params: { pipelineId, pipelineToken, testId } }) => ({
    // This route will wait for the parent loaderPromise to resolve before finding the individual team
    user: await new Promise((resolve, reject) => {
        // check if there's a user
        // if there is, we can resolve that tehre
        // otherwise do the rest of the flow

        checkAuthUser().then((user) => {
            if (user) {
                resolve(user);
                return;
            }
            v2AuthDataViewer({ id: pipelineId, token: pipelineToken }).then(
                ({
                    data: {
                        data: { token },
                    },
                }: any) => signInWithCustomToken(auth, token).then(resolve).catch(reject),
            );
        });
    }),

    pipelineId,
    pipelineToken,
    testId,
});

type PipelineGenerics = MakeGenerics<{
    LoaderData: {
        pipelineId: string;
        pipelineToken: string;
        testId: string;
    };
}>;

function MapRedirect() {
    const {
        data: { pipelineId, pipelineToken, testId },
    } = useMatch<PipelineGenerics>();

    return <Navigate to={`/${pipelineId}/${pipelineToken}/${testId}/map`} />;
}

function AuthRedirect() {
    const [navigating, setNavigating] = useState(false);
    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            if (user) {
                setNavigating(true);
            }
        });
    }, []);

    return navigating ? <Navigate to="/home" /> : <LoginPage />;
}

function AuthGuard({ children }: { children: JSX.Element }) {
    const [navigating, setNavigating] = useState(false);
    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            if (!user) {
                setNavigating(true);
            }
        });
    }, []);

    return navigating ? <Navigate to="/login" /> : children;
}

const routes: Route[] = [
    { path: '/', element: <Navigate to="/login" /> },
    {
        path: '/login',
        element: <AuthRedirect />,
    },
    {
        path: '/home',
        element: (
            <AuthGuard>
                <HomePage />
            </AuthGuard>
        ),
    },
    {
        path: '/:pipelineId/:pipelineToken/:testId/map',
        element: <PipelineTestMap />,
        loader: PipelineFetcher,
        errorElement: <Navigate to="/login" />,
    },
    {
        path: '/:pipelineId/:pipelineToken/:testId/summary',
        element: <PipelineTestSummary />,
        loader: PipelineFetcher,
        errorElement: <Navigate to="/login" />,
    },
    {
        path: '/:pipelineId/:pipelineToken/:testId',
        loader: async ({ params: { pipelineId, pipelineToken, testId } }) => {
            return {
                pipelineId,
                pipelineToken,
                testId,
            };
        },
        element: <MapRedirect />,
    },
    {
        path: '/:pipelineId/:pipelineToken',
        element: <PipelinePage />,
        loader: PipelineFetcher,
        pendingElement: <p> LOADING </p>,
        errorElement: <Navigate to="/login" />,
    },
];

function Routing({ children }: { children?: React.ReactNode }) {
    return (
        <Router routes={routes} location={location}>
            {children}
        </Router>
    );
}

export default Routing;
