import IWeldRun from 'Types/WeldRun';
import getSpeedStamp from './getSpeedStamp';

function formatWeldRuns(weldRuns: IWeldRun[]) {
    return weldRuns.map(({ distance, elapsed, cid, start }) => ({
        start: start * 1000,
        contractor: cid,
        distance,
        elapsed_time: `${elapsed}s`,
        weld_joint_speed: getSpeedStamp(distance, elapsed),
    }));
}

export default formatWeldRuns;
