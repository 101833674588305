import DownloadRun from 'Components/DownloadRun';
import usePipe from 'Hooks/usePipe';

function PipelineToggleLegend({
    showLegend,
    setShowLegend,
    pipeId,
    testId,
}: {
    showLegend: boolean;
    setShowLegend: (val: boolean) => void;
    pipeId: string;
    testId: string;
}) {
    const [pipe] = usePipe({ pipeId });

    return (
        <div className="mt-3 lg:mt-0 px-6 pb-4 space-y-6 md:space-y-0 flex flex-col md:flex-row items-start lg:items-center justify-between">
            <div className="text-md">{`${pipe?.name} ${pipe?.description}`}</div>

            <div className="inline-flex text-sm">
                <DownloadRun pipeId={pipeId} testId={testId} />
                <button
                    onClick={() => setShowLegend(!showLegend)}
                    className="py-1 px-2 rounded rounded-l-none border border-gray-00"
                    type="button"
                >
                    {showLegend ? 'Hide Legend' : 'Show Legend'}
                </button>
            </div>
        </div>
    );
}

export default PipelineToggleLegend;
